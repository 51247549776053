import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";

function Home(props) {
  return (
    <div
      className="content"
      style={{
        paddingLeft: "25%",
        paddingRight: "25%",
      }}
    >
      <h1>web developer portfolio</h1>
      <p>Full Stack Web Developer</p>
      <p
        style={{
          borderRadius: "6px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "2px",
          paddingBottom: "2px",
          boxSizing: "border-box",
          boxShadow: "0 2px 2px rgba(204, 197, 185, 0.5)",
          backgroundColor: "rgb(51, 51, 51)",
        }}
      >
        I started learning how to code a few years ago during my insurance
        career. We used SharePoint and maintained a large directory to internal
        and external web-apps. I recognized that the design could be improved
        and challenged myself to build a better user experience. I had a lot of
        great feedback, users were happy, and time spent navigating was reduced.
        I quickly realized I was hooked. Fast-forward to now, I decided to take
        a new career direction and have been learning full time.
      </p>
      <div
        style={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}
      >
        <Link to="/projects">
          <RightArrow className="arrow"></RightArrow>
        </Link>
      </div>
    </div>
  );
}
export default Home;
