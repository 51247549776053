import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";
import { ReactComponent as LeftArrow } from "./images/leftarrow.svg";

function Telephone(props) {
  return (
    <>
      <iframe
        style={{ width: "100%" }}
        scrolling="no"
        title="Telephone Number Validator"
        src="https://codepen.io/kitoshi/embed/preview/KKmXmrp?default-tab=html%2Cresult&editable=true&theme-id=dark"
        loading="lazy"
        allowtransparency="true"
        allowFullScreen={true}
        height={300}
        frameBorder="no"
      >
        See the Pen &lt;a href="https://codepen.io/kitoshi/pen/KKmXmrp"&gt;
        Telephone Number Validator&lt;/a&gt; by robert charlton (&lt;a
        href="https://codepen.io/kitoshi"&gt;@kitoshi&lt;/a&gt;) on &lt;a
        href="https://codepen.io"&gt;CodePen&lt;/a&gt;.
      </iframe>
      <Link to="/cipher">
        <LeftArrow className="arrow"></LeftArrow>
      </Link>
      <Link to="/register">
        <RightArrow className="arrow"></RightArrow>
      </Link>
    </>
  );
}

export default Telephone;
