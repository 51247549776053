import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";
import { ReactComponent as LeftArrow } from "./images/leftarrow.svg";

function Palindrome(props) {
  return (
    <>
      <iframe
        style={{ width: "100%" }}
        scrolling="no"
        title="Palindrome Checker"
        src="https://codepen.io/kitoshi/embed/preview/dyWVWqm?default-tab=js%2Cresult&editable=true&theme-id=dark"
        loading="lazy"
        allowtransparency="true"
        allowFullScreen={true}
        height={300}
        frameBorder="no"
      >
        See the Pen &lt;a href="https://codepen.io/kitoshi/pen/dyWVWqm"&gt;
        Palindrome Checker&lt;/a&gt; by robert charlton (&lt;a
        href="https://codepen.io/kitoshi"&gt;@kitoshi&lt;/a&gt;) on &lt;a
        href="https://codepen.io"&gt;CodePen&lt;/a&gt;.
      </iframe>
      <Link to="/auction">
        <LeftArrow className="arrow"></LeftArrow>
      </Link>
      <Link to="/numerals">
        <RightArrow className="arrow"></RightArrow>
      </Link>
    </>
  );
}

export default Palindrome;
