import React from "react";
import Thumbnail from "./Thumbnail.js";
import Buysell from "./images/buylowsellhigh.jpg";
import Codepen from "./images/codepen.jpg";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";
import { ReactComponent as LeftArrow } from "./images/leftarrow.svg";
import { Link } from "react-router-dom";

function Projects(props) {
  return (
    <div className="content">
      <h1>projects</h1>
      <ul
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Thumbnail
          link="/auction"
          image={Buysell}
          title="Auction Crawler"
          category="Web App"
        />
        <Thumbnail
          link="/palindrome"
          image={Codepen}
          title="Palindrome Checker"
          category="Function"
        />
        <Thumbnail
          link="/numerals"
          image={Codepen}
          title="Roman Numeral Converter"
          category="Function"
        />
        <Thumbnail
          link="/cipher"
          image={Codepen}
          title="Ceasar's Cipher"
          category="Function"
        />
        <Thumbnail
          link="/telephone"
          image={Codepen}
          title="Telephone Number Checker"
          category="Function"
        />
        <Thumbnail
          link="/register"
          image={Codepen}
          title="Cash Register"
          category="Function"
        />
      </ul>
      <div
        style={{ maxWidth: "100%", justifyContent: "center", display: "flex" }}
      >
        <Link to="/">
          <LeftArrow className="arrow"></LeftArrow>
        </Link>
        <Link to="/resume">
          <RightArrow className="arrow"></RightArrow>
        </Link>
      </div>
    </div>
  );
}
export default Projects;
