import React from "react"; // Import the Component component from React
import { Link } from "react-router-dom"; // Import the Link component

function Thumbnail(props) {
  return (
    <>
      <li
        className="project"
        style={{ width: "25%", marginLeft: "2%", marginRight: "2%" }}
      >
        <Link to={props.link}>
          <div className="project-image">
            <img
              src={props.image}
              alt="Project"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <div className="project-title">
            {props.title} {props.category}
          </div>
          <div className="project-category"></div>
        </Link>
      </li>
    </>
  );
}

export default Thumbnail;
