import React from "react";
import Tradingfever from "./images/tradingfever.png";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";
import { ReactComponent as LeftArrow } from "./images/leftarrow.svg";
import Server from "./images/server.svg";
import Window from "./images/window.svg";
import Live from "./images/live.svg";

function Auction(props) {
  return (
    <>
      <h1>Auction Crawler</h1>
      <p
        style={{
          maxWidth: "100%",
          alignItems: "center",
          paddingRight: "10%",
          paddingLeft: "10%",
        }}
      >
        I love finding a good deal and flipping items for profit. I found a
        small niche on government surplus websites where I could buy at
        wholesale prices and sell locally or on Ebay. A lot of time was spent
        comparing the auction prices to online stores and ebay. This web-app is
        designed to centralize and automate all that, allowing myself and others
        to spot a deal quickly out of hundreds of items, and click through
        efficiently.
      </p>
      <ul
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <li style={{ width: "100%", fontSize: "20px" }}>
          <a href="https://github.com/kitoshi/auctioncrawl-api">
            <img src={Server} alt="server"></img>
            Backend: Node.js
          </a>
        </li>
        <li style={{ width: "100%", fontSize: "20px" }}>
          <a href="https://github.com/kitoshi/auctioncrawl">
            <img src={Window} alt="ui"></img>Frontend: React
          </a>
        </li>
        <li style={{ width: "100%", fontSize: "20px" }}>
          <a href="https://www.tradingfever.com">
            <img src={Live} alt="live icon"></img>Live Web App
          </a>
        </li>
        <li style={{ width: "100%" }}>
          Libraries/Frameworks: Express, Puppeteer, Cheerio, Redis
        </li>
        <li style={{ width: "100%" }}>
          Hosting: Google Cloud Platform with App Engine
        </li>
        <a href="https://www.tradingfever.com" style={{ width: "50%" }}>
          <img
            src={Tradingfever}
            alt="Live Screenshot"
            style={{ width: "100%" }}
          ></img>
        </a>
      </ul>
      <Link to="/register">
        <LeftArrow className="arrow"></LeftArrow>
      </Link>
      <Link to="/palindrome">
        <RightArrow className="arrow"></RightArrow>
      </Link>
    </>
  );
}

export default Auction;
