import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import "./App.css";

/*
synchronizes with the browser and allows a second component, 
Route, to control which component should appear for which URL. 
A third component, Link, allows for transitions between routes.
*/
import Projects from "./Projects.js";
import Resume from "./Resume.js";
import Home from "./Home.js";
import Auction from "./Auction.js";
import Palindrome from "./Palindrome.js";
import Numerals from "./Numerals";
import Register from "./Register";
import Telephone from "./Telephone";
import Cipher from "./Cipher";
import Sun from "./images/sun.svg";
import DarkSun from "./images/darksun.svg";

function LightMode() {
  //css theme toggle for darkmode/lightmode
  document.getElementById("app").classList.toggle("light-theme");
  document.getElementsByClassName("navitem");
  for (let i = 0; i < document.getElementsByClassName("navitem").length; i++) {
    document
      .getElementsByClassName("navitem")
      [i].classList.toggle("light-theme");
  }
  document.body.classList.toggle("light-theme");
  document.getElementsByClassName("navBorder");
  for (
    let i = 0;
    i < document.getElementsByClassName("navBorder").length;
    i++
  ) {
    document
      .getElementsByClassName("navBorder")
      [i].classList.toggle("light-theme");
  }
}

function App() {
  const [lightMode, setLightMode] = React.useState(false);

  return (
    <BrowserRouter>
      <div className="app" id="app">
        <ul className="navBorder">
          <a
            href="https://www.linkedin.com/in/robert-k-charlton/"
            style={{ fontSize: "16px", float: "left" }}
            className="navitem"
          >
            Kitoshi
          </a>
          <li
            style={{
              float: "left",
              position: "relative",
              paddingTop: "0.7%",
              display: "block",
            }}
          >
            <button
              style={{
                float: "left",
                position: "relative",
                background: "transparent",
                boxShadow: "0px 0px 0px transparent",
                border: "0px solid transparent",
                cursor: "pointer",
                padding: "none",
              }}
            >
              <img
                src={lightMode ? DarkSun : Sun}
                alt="Theme"
                onClick={() => {
                  LightMode();
                  setLightMode(
                    (prevMode) => !prevMode /* hook for button image change*/
                  );
                }}
                id="sun"
              ></img>
            </button>
          </li>

          <li>
            <Link to="/resume" className="navitem" style={{ float: "right" }}>
              Resume
            </Link>
          </li>
          <li>
            <Link to="/projects" className="navitem" style={{ float: "right" }}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="/" className="navitem" style={{ float: "right" }}>
              Home
            </Link>
          </li>
        </ul>
        <Route exact path="/" component={Home} />
        <Route path="/resume" component={Resume} />
        <Route path="/projects" component={Projects} />
        <Route path="/auction" component={Auction} />
        <Route path="/palindrome" component={Palindrome} />
        <Route path="/numerals" component={Numerals} />
        <Route path="/register" component={Register} />
        <Route path="/telephone" component={Telephone} />
        <Route path="/cipher" component={Cipher} />
      </div>
    </BrowserRouter>
  );
}

export default App;
