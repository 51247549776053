import React from "react";
import Html5 from "./images/html5.svg";
import Css3 from "./images/css-3.svg";
import JScript from "./images/javascript.svg";
import GCloud from "./images/google-cloud.svg";
import Nodejs from "./images/node-white.svg";
import Reacticon from "./images/react-1.svg";
import DownloadImage from "./images/download.svg";
import "./App.css";
import { ReactComponent as RightArrow } from "./images/rightarrow.svg";
import { ReactComponent as LeftArrow } from "./images/leftarrow.svg";
import { Link } from "react-router-dom";
import ResumeDoc from "./documents/RobertCharltonResume2021.pdf";

function Resume(props) {
  return (
    <div className="content">
      <h1>resume</h1>
      <div>
        <ul
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            paddingLeft: "5%",
            paddingRight: "5%",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <li>
            <img src={Css3} className="circle" alt="CSS3"></img>
            <img src={Html5} className="circle" alt="CSS3"></img>
            <img src={JScript} className="circle" alt="HTML5"></img>
          </li>
          <li>
            <img src={GCloud} className="circle" alt="Google Cloud"></img>
            <img src={Nodejs} className="circle" alt="NodeJS"></img>
            <img src={Reacticon} className="circle" alt="React"></img>
          </li>
        </ul>
      </div>
      <div>
        <a href={ResumeDoc} download="RobertCharltonResume2021.pdf">
          {" "}
          <img
            src={DownloadImage}
            alt="Resume Download"
            className="circle"
            style={{
              height: "50px",
              backgroundColor: "gray",
              borderRadius: "50%",
            }}
          ></img>{" "}
        </a>
      </div>
      <div
        style={{
          maxWidth: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Link to="/projects">
          <LeftArrow className="arrow"></LeftArrow>
        </Link>
        <Link to="/">
          <RightArrow className="arrow"></RightArrow>
        </Link>
      </div>
    </div>
  );
}
export default Resume;
